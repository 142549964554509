"use client";
import algoliasearch from "algoliasearch/lite";
import Autocomplete from "downshift";
import { useRouter } from "next/navigation";
import React, { useRef, useState, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import * as gtm from "~/helpers/analytics/gtm";
import { isNotEmpty, manualHtmlDecode } from "~/helpers/stringHelpers";

import SearchResult from "./SearchResult";
import nProgress from "nprogress";

export const getTargetUrl = (item: any) => {
  if (item.urlName) {
    if (item.alternatives && item.alternatives > 0) {
      return "/software/" + item.urlName + "/";
    } else {
      return "/software/" + item.urlName + "/about/";
    }
  } else {
    if (item.type === "Feature") {
      return "/feature/" + item.groupName;
    } else {
      return "/tag/" + item.groupName;
    }
  }
};

interface SearchWrapperProps {
  customClass?: string;
  show?: boolean;
  isMobile?: boolean;
}

const SearchWrapper = (props: SearchWrapperProps) => {
  const { customClass, show, isMobile } = props;

  const [showSearch, setShowSearch] = useState(false);

  if (isMobile === undefined) return null;

  return (
    <div
      className={`transition-all duration-300 flex items-center justify-end opacity-0 
      ${customClass === "startPage" ? "relative w-full" : ""} 
      ${show ? "opacity-100" : "opacity-0"}`}
    >
      {!(isMobile && customClass != "startPage") || showSearch ? (
        <Search
          onBlur={() => setShowSearch(false)}
          focusOnLoad={showSearch}
          customClass={customClass}
        ></Search>
      ) : (
        <button
          value="Search"
          aria-label="Search"
          className="p-[7px] appearance-none border-none rounded text-[var(--brandLight8)] text-base bg-transparent leading-none mr-[0.3em] relative top-[1px]"
          onClick={() => setShowSearch(!showSearch)}
        >
          <FaSearch></FaSearch>
        </button>
      )}
    </div>
  );
};

interface SearchProps {
  customClass?: string;
  focusOnLoad?: boolean;
  onBlur(): any;
}

const Search = (props: SearchProps) => {
  const { customClass, focusOnLoad, onBlur } = props;

  const [searchData, setSearchData] = useState([]);
  const [textValue, setTextValue] = useState("");
  const [isSearchType, setIsSearchType] = useState("app");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const router = useRouter();

  const formRef = useRef(null);
  const inputRef = useRef(null);

  const client = algoliasearch(
    "ZIDPNS2VB0",
    "88489cdf3a8fbfe07a2f607bf1568330",
  );

  const algoliaItemIndex = process.env.NEXT_PUBLIC_ALGOLIA_ITEM_INDEX;
  const algoliaTagIndex = process.env.NEXT_PUBLIC_ALGOLIA_TAG_INDEX;

  const index = client.initIndex(algoliaItemIndex);
  const index_tags = client.initIndex(algoliaTagIndex);

  const getItems = (value: string) => {
    if (value) {
      const tagPrefixRegex = /^tags?:/i; // Regular expression to match "tags:" or "tag:" (case-insensitive)

      if (!tagPrefixRegex.test(value)) {
        index
          .search(value, { hitsPerPage: 10 })
          .then(({ hits }) => {
            setIsSearchType("app");
            setSearchData(hits);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        const searchValue = value.toLowerCase().replace(tagPrefixRegex, "");
        index_tags
          .search(searchValue, { hitsPerPage: 10 })
          .then(({ hits }) => {
            setIsSearchType("tag");
            setSearchData(hits);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const submitForm = () => {
    if (textValue) {
      formRef.current.submit();
      inputRef.current.blur();
    }
  };

  const blurForm = () => {
    inputRef.current.blur();
  };

  const sendEvent = (item: any) => {
    if (item.urlName) {
      gtm.event("ClickSearchResult", {
        urlName: item.urlName,
      });
    }
  };

  // Reset expanded state when window is resized above mobile breakpoint
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && isExpanded) {
        setIsExpanded(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isExpanded]);

  // Updated classes to use a more semantic structure
  const containerClasses = `
    ${
      customClass === "startPage"
        ? "w-full"
        : isFocused
          ? "w-[300px] md:w-[360px]"
          : "w-[300px] md:w-[250px]"
    }
    ${isExpanded ? "z-[9999]" : "z-[999]"}
    relative transition-all duration-300 ease-in-out
  `;

  // Mobile expanded state
  const mobileExpandedClasses = isExpanded
    ? `
      md:relative fixed inset-x-0 top-0 p-[10px] 
      bg-[var(--mainBrand)] flex flex-col
    `
    : "relative";

  return (
    <Autocomplete
      labelId="search-label"
      inputId="search-input"
      menuId="search-menu"
      onOuterClick={() => {
        // Clear input text
        setTextValue("");

        // Blur the input field
        if (inputRef.current) {
          inputRef.current.blur();
        }

        // Clear search results
        setSearchData([]);

        setIsExpanded(false);
        onBlur();
      }}
      initialInputValue=""
      itemToString={(i) => (i ? manualHtmlDecode(i.name) : i)}
      onInputValueChange={(inputValue) => getItems(inputValue)}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getLabelProps,
        isOpen,
        highlightedIndex,
        openMenu,
        closeMenu,
        inputValue,
        reset,
      }) => {
        // Handle close button click
        const handleLocalClose = () => {
          // Clear input text
          setTextValue("");

          // Blur the input field
          if (inputRef.current) {
            inputRef.current.blur();
          }

          // Clear search results
          setSearchData([]);

          // Reset Downshift completely - clears inputValue and more
          reset();

          // Close the dropdown menu
          closeMenu();

          setIsExpanded(false);
          onBlur();
        };

        return (
          <div
            className={`${containerClasses} ${mobileExpandedClasses}`}
            data-testid="real-search"
          >
            <form
              method="get"
              action="/browse/search"
              ref={formRef}
              className="flex flex-col w-full relative"
            >
              <label className="hidden" {...getLabelProps()}>
                Search
              </label>

              {/* Search input container */}
              <div className="relative w-full">
                {/* Single close button that works in all scenarios */}
                {(isNotEmpty(inputValue) || isExpanded) && (
                  <button
                    type="button"
                    className="absolute top-1/2 transform -translate-y-1/2 right-[5px] bg-transparent border-none appearance-none text-[var(--mainBrand)] z-[50] p-1 rounded-full hover:bg-gray-100 text-sm cursor-pointer transition-colors"
                    onClick={handleLocalClose}
                    aria-label="Close search"
                  >
                    <FaTimes />
                  </button>
                )}

                <input
                  ref={inputRef}
                  type="search"
                  name="q"
                  maxLength={200}
                  value={inputValue}
                  autoFocus={focusOnLoad ? true : false}
                  placeholder="Find an alternative to…"
                  className={`
                    w-full block py-[7px] px-[7px] pl-[38px] pr-[38px] appearance-none border-none 
                    ${isOpen ? "rounded-t-[12px]" : "rounded-[12px]"}
                    md:text-[0.85em] text-[var(--mainFg)] transition-all 
                    duration-200 bg-[var(--mainBg)]! box-border [-webkit-appearance:none]
                    ${
                      customClass === "startPage"
                        ? "p-[10px] pl-[34px] pr-[34px] md:text-[1.1em] shadow-[0_0_6px_3px_var(--brandLight3)] border border-solid border-[var(--mainBrand)] focus:border focus:border-solid focus:border-[var(--mainBrand)]"
                        : "focus:outline-none focus:border focus:border-solid focus:border-[var(--brandLight5)]"
                    }
                    /* Mobile browsers don't zoom when input font size is at least 16px */
                    text-[16px]
                  `}
                  {...getInputProps({
                    value: textValue,
                    onChange: (e) => {
                      setTextValue(e.target.value);
                    },

                    onKeyDown: (e) => {
                      // Enter
                      if (e.key == "Enter") {
                        if (highlightedIndex !== null) {
                          nProgress.start();
                          router.push(
                            getTargetUrl(searchData[highlightedIndex]),
                          );

                          setIsFocused(false);
                          blurForm();
                          handleLocalClose();
                          e.preventDefault();
                        } else {
                          if (isSearchType === "app") {
                            if (isNotEmpty(inputValue)) {
                              submitForm();
                              handleLocalClose();
                              e.preventDefault();
                            }
                          }
                        }
                      }
                    },
                    onKeyUp: (e) => {
                      // Escape
                      if (e.key === "Escape") {
                        setIsFocused(false);
                        blurForm();
                        handleLocalClose();
                      }
                    },
                    onFocus: () => {
                      setIsFocused(true);
                      if (window.innerWidth < 768) {
                        setIsExpanded(true);
                      }
                      openMenu();
                    },
                    onBlur: () => {
                      setIsFocused(false);
                      if (!isExpanded) {
                        onBlur();
                      }
                    },
                  })}
                />

                <button
                  disabled={!isNotEmpty(inputValue)}
                  style={!isNotEmpty(inputValue) ? { cursor: "default" } : {}}
                  type="submit"
                  value="Find"
                  aria-label="Submit search"
                  className={`
                    absolute top-1/2 transform -translate-y-1/2 left-[10px] right-auto
                    block appearance-none border-none text-[var(--mainBrand)] text-sm
                    bg-transparent leading-none p-0 z-10
                    ${isExpanded ? "text-[var(--mainBrand)]" : ""}
                  `}
                >
                  <FaSearch></FaSearch>
                </button>
              </div>

              {/* Search results - positioned within the form context */}
              {isOpen && (
                <SearchResult
                  getItemProps={getItemProps}
                  getMenuProps={getMenuProps}
                  highlightedIndex={highlightedIndex}
                  inputValue={textValue}
                  onClick={(item) => {
                    sendEvent(item);
                    setIsFocused(false);
                    blurForm();
                    handleLocalClose();
                  }}
                  searchData={searchData}
                  isSearchType={isSearchType}
                  setTextValue={() => {
                    setTextValue("tags:");
                    inputRef.current.focus();
                  }}
                  customClass={customClass}
                  onClose={handleLocalClose}
                />
              )}
            </form>
          </div>
        );
      }}
    </Autocomplete>
  );
};

export default SearchWrapper;
