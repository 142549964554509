"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./PageIntroImage.module.scss";
import { useTheme } from "next-themes";

interface PageIntroImageProps {
  size?: number;
  name: string;
}

const PageIntroImage = (props: PageIntroImageProps) => {
  const { size = 134, name } = props;
  const { resolvedTheme, theme } = useTheme();

  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (resolvedTheme === "dark" || theme === "dark") {
      setImgSrc(`/static/${name}-dark.svg`);
    } else {
      setImgSrc(`/static/${name}.svg`);
    }
  }, [resolvedTheme, theme]);

  return (
    <>
      {imgSrc && (
        <Image
          alt="Just a cute robot"
          className={`${styles.image} ${styles[props.name]}`}
          src={imgSrc}
          width={size}
          height={size}
        />
      )}
    </>
  );
};
export default PageIntroImage;
