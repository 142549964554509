import { parseCookies, setCookie } from "nookies";
import { NextPageContext, NextApiRequest, NextApiResponse } from "next";

export interface A2Cookie {
  sId: string;
  showLw: boolean;
}

/**
 * Extracts and returns the cookies from the context object
 *
 * @param ctx - context object
 */
export const getCookies = (
  ctx?:
    | Pick<NextPageContext, "req">
    | { req: NextApiRequest }
    | null
    | undefined
): {
  [key: string]: string;
} => {
  let cookies = {};

  if (ctx && ctx.req) {
    cookies = parseCookies(ctx);
  } else {
    cookies = parseCookies();
  }

  return cookies;
};

export const getA2Cookie = (cookies: any): A2Cookie => {
  const a2Cookie: A2Cookie = { sId: "0", showLw: false };

  if (!cookies["a2"]) {
    return a2Cookie;
  } else {
    const values = cookies["a2"].split("|");
    return { sId: values[0], showLw: values[1] === "true" };
    //return JSON.parse(cookies["a2"]) as A2Cookie;
  }
};

export const getA2CookieFromCtx = (
  ctx?:
    | Pick<NextPageContext, "req">
    | { req: NextApiRequest }
    | null
    | undefined
): A2Cookie => {
  const cookies = getCookies(ctx);

  return getA2Cookie(cookies);
};

export const setCustomCookie = (
  name: string,
  a2Cookie: A2Cookie,
  ctx:
    | Pick<NextPageContext, "res">
    | {
        res: NextApiResponse;
      }
    | null
    | undefined
) => {
  const newValue = a2Cookie.sId + "|" + a2Cookie.showLw;

  setCookie(ctx, name, newValue, {
    path: "/",
  });
};
