import { GetItemPropsOptions } from "downshift";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { FaCogs, FaTag } from "react-icons/fa";
import { manualHtmlDecode } from "~/helpers/stringHelpers";

import { getTargetUrl } from "./Search";
import AppIcon from "~/c/AppCommon/AppIcon";

interface SearchResultProps {
  inputValue: string;
  getMenuProps(): any;
  getItemProps(options: GetItemPropsOptions<any>): any;
  highlightedIndex: number;
  searchData: any[];
  onClick(item: any): any;
  isSearchType: string;
  setTextValue(value: string): any;
  customClass?: string;
  onClose?(): void;
}

const SearchResult = (props: SearchResultProps) => {
  const {
    inputValue,
    getMenuProps,
    getItemProps,
    searchData,
    highlightedIndex,
    onClick,
    isSearchType,
    setTextValue,
    customClass,
  } = props;

  // Don't render if we have no search data and no input
  //if (searchData.length === 0 && !inputValue) return null;

  return (
    <div
      className={`absolute top-full left-0 w-full shadow-md rounded-b-xl bg-main-bg animate-fadeIn overflow-hidden z-[9999]
        ${customClass === "startPage" ? "border border-[var(--mainBrand)] border-t-0" : ""}
        ${searchData.length > 0 ? "border-t-0" : ""}`}
      {...getMenuProps()}
    >
      {inputValue && searchData.length > 0 ? (
        <div className="max-h-[calc(90vh-70px)] overflow-y-auto">
          {searchData.map((item, index) => {
            let icon = null;

            if (isSearchType === "app") {
              icon =
                item.images &&
                item.images.find((image) => image.type === "Icon");
            }

            return (
              <div
                translate="no"
                className={`${
                  highlightedIndex === index
                    ? "dark:bg-transparent bg-[var(--brandLight6)]"
                    : "bg-transparent dark:bg-[var(--brandLight6)]"
                }`}
                key={index}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                <Link
                  onClick={() => {
                    onClick(item);
                  }}
                  className={`block overflow-hidden ${
                    isSearchType === "tag" ? "py-2 px-3" : "py-2 px-3 pl-[60px]"
                  } border-b border-[var(--brandLight5)] no-underline relative ${
                    customClass === "startPage" ? "text-left" : ""
                  }`}
                  href={`${getTargetUrl(item)}`}
                >
                  {isSearchType === "tag" && (
                    <span>
                      {item.Type == "Feature" ? (
                        <FaCogs className="w-4 mr-2 relative top-0.5 inline-block" />
                      ) : (
                        <FaTag className="w-4 mr-2 relative top-0.5 inline-block" />
                      )}{" "}
                      {item.longText && <>{item.longText}</>}
                      {!item.longText && <>{item.name}</>}
                    </span>
                  )}
                  {isSearchType === "app" && (
                    <React.Fragment>
                      {icon && (
                        <AppIcon
                          appName={item.name}
                          imgObject={
                            item &&
                            item.images &&
                            item.images.find((image) => image.type === "Icon")
                          }
                          dimension={70}
                          className="w-[40px] h-[40px] absolute left-[10px] translate-y-[3px] rounded-[2px]"
                        />
                      )}
                      <strong
                        className={`text-[1.1em] ${customClass === "startPage" ? "text-left" : ""}`}
                      >
                        {manualHtmlDecode(item.name)}
                      </strong>
                      <div
                        className={`text-sm text-[var(--meta)] ${customClass === "startPage" ? "text-left" : ""}`}
                        translate="yes"
                      >
                        {item.alternatives > 0 && (
                          <span>
                            We have{" "}
                            <span className="font-medium">
                              {item.alternatives} alternatives
                            </span>
                          </span>
                        )}
                        {item.alternatives <= 0 && <span>Show app info</span>}
                      </div>
                    </React.Fragment>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      ) : null}

      <div className="py-3 px-3 text-sm border-b border-[var(--gray100)] flex justify-between items-center">
        <div>
          Looking for tags / features? Type{" "}
          <span
            onClick={() => {
              setTextValue("tags:");
            }}
            className="cursor-pointer text-[var(--linkColor)] font-bold"
          >
            tags:
          </span>{" "}
          and then a query.
        </div>
      </div>
      <a
        className="block text-center py-[10px] pb-[5px] w-[180px] mx-auto"
        href="https://www.algolia.com/"
      >
        <Image
          width={160}
          height={59}
          src="/static/search-by-algolia.svg"
          alt="Search Provided by Algolia"
        />
      </a>
    </div>
  );
};
export default SearchResult;
