import React from "react";
import styles from "./NumberCounter.module.scss";

type NumberCounterProps = {
  displayNumber: number;
};

const NumberCounter = (props: NumberCounterProps) => {
  const { displayNumber } = props;

  let formattedNumber = "0";

  //const [digits, setDigits] = useState(displayNumber.toString());

  // if (displayNumber > 1e6) {
  //   formattedNumber = (displayNumber / 1e6).toFixed(1) + "M";
  // } else if (displayNumber > 1e3) {
  //   formattedNumber = (displayNumber / 1e3).toFixed(0) + "K";
  // } else if (displayNumber === 1) {
  //   formattedNumber = "One";
  // } else {
  formattedNumber = displayNumber.toLocaleString("en-US");
  // }

  // if (displayNumber > 1e6) {
  //   formattedNumber = (displayNumber / 1e6).toFixed(1) + "M";
  // } else if (displayNumber > 1e3) {
  //   formattedNumber = (displayNumber / 1e3).toFixed(0) + "K";
  if (displayNumber === 1) {
    formattedNumber = "One";
  } else {
    formattedNumber = displayNumber.toLocaleString("en-US");
  }

  return <span className={styles.num}>{formattedNumber}</span>;
};
export default NumberCounter;
