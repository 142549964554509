"use client";

import { useContext, useEffect, useState } from "react";
import { RootContext } from "~/components/Contexts/RootContext";
import { getCookies } from "~/helpers/cookieHelper";

export default function LoginMessageHandler() {
  const [loginMessage, setLoginMessage] = useState<string>();
  const rootContext = useContext(RootContext);

  useEffect(() => {
    const cookies = getCookies();

    if (cookies["login_failed"]) {
      switch (cookies["login_failed"]) {
        case "NoUserWithID":
          setLoginMessage(
            "Something went wrong when you logged in / signed up (Code: NoUserWithID). You need to contact support and we will help you ASAP to recover your account, please drop an email to support@alternativeto.net",
          );
          break;
        // ... rest of the cases ...
      }
    }
  }, []);

  useEffect(() => {
    if (loginMessage) {
      rootContext.setError(loginMessage, "Login Error");
      document.cookie = "login_failed=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  }, [loginMessage]);

  return null;
}
