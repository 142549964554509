"use client";

import Link from "next/link";
import React, { useContext } from "react";
import { RootContextValues } from "~/typings/types";
import { RootContext } from "./Contexts/RootContext";
import SearchWrapper from "~/c/Search";
import Spacer from "~/c/Spacer";
import Heading from "~/c/Heading";
import NumberCounter from "~/c/Common/NumberCounter";
import styles from "./WelcomeMsg.module.scss";
import PageIntroImage from "~/c/PageIntroImage";

const WelcomeMsg = ({ startPageStats }) => {
  const rootContext = useContext<RootContextValues>(RootContext);

  const user = rootContext.user;

  return (
    <div className={styles.wrapper}>
      <PageIntroImage name="a2-header-left"></PageIntroImage>

      <div className={styles.introText}>
        <Heading look="h3">
          <NumberCounter displayNumber={startPageStats.apps}></NumberCounter>{" "}
          Apps & Software Alternatives - Ranked by{" "}
          <NumberCounter
            displayNumber={startPageStats.opinions + startPageStats.likes}
          ></NumberCounter>{" "}
          User Opinions
        </Heading>

        <Spacer space={1}></Spacer>
        <SearchWrapper
          show={true}
          isMobile={rootContext.isMobile}
          customClass="startPage"
        ></SearchWrapper>
        <Spacer space={1}></Spacer>
        <div className="meta">
          {!user ? (
            <>
              <p>
                AlternativeTo is a crowd-sourced and free site that helps you
                find better apps and services.{" "}
                <a className="underline" href="api/auth/signup/">
                  Sign up
                </a>{" "}
                and help others find great apps!
              </p>
            </>
          ) : (
            <>
              Welcome{" "}
              <Link className="underline" href={`/user/${user.urlNick}/`}>
                {user.nick}
              </Link>
              , are you looking for a new alternative? Or maybe suggest{" "}
              <Link className="underline" href="/manage-item/">
                a new one
              </Link>
              ?
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default WelcomeMsg;
